import React, { useContext, useState } from "react";

import {mfaContext} from "..";
import {Button} from "react-bootstrap";
import OtpInput from "../../../otp-input";
import config from "../../../../config";

const MFATotpPanel = () => {
  const {loading, DataHelper, handleApprove, userData, inputFocus, setInputFocus, requestCode, requestCodeVerified, retryEnabled, handleRetry, retryTimer, setMfaMethod, stopPolling, setRetryTimer, setShowMfa} = useContext(mfaContext);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [otpError, setOtpError] = useState(null);
  const [otpLoading, setOtpLoading] = useState(false);

  const handleOtpSubmit = async (otp) => {
    setOtpLoading(true);
    const apiPath = config.backend;
    const data = await fetch(`${apiPath}rest.api/verify/${requestCode}:${otp}`, {
      method: "GET",
    });

    const parsedData = await data.json();

    if (parsedData.success) {
      handleApprove();
    } else {
      setOtpError("Invalid code");
    }

    setOtpLoading(false);
  };

  return (
    <div className="mfa-panel">
      <p className="mfa-heading pb-3">{"2FA Code Generator"}</p>
      <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
        "We have identified that you have registered a 2FA device."}
      </p>
      <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
        "Enter the code from the registered device to continue."}
      </p>
      <OtpInput
        count={6}
        value={otp}
        onChange={value => {
          setOtp(value);
          setOtpError(null);
        }}
      />
      {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
      <div className="mfa-actions-container">
        {!requestCodeVerified && otp.join("").length === 6 &&<Button
          className="submit-otp-button"
          onClick={() => handleOtpSubmit(otp.join(""))}
          disabled={otpLoading}
        >
          {"Submit"}
        </Button>}
      </div>

      <hr className="divider"/>
      <div className="option-list-actions">
        <span className="option-list-item" onClick={() => setMfaMethod(null)}>
          {"Use another method to receive the code"} 
        </span>
        <span className="option-list-item" onClick={() => setShowMfa(false)}>
          {"Sign in to a different account"} 
        </span>
      </div>
    </div>
  );
};
export default MFATotpPanel;