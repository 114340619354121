import React, { useContext, useState } from "react";
import moment from "moment-mini";

import {mfaContext} from "..";
import {Button} from "react-bootstrap";
import OtpInput from "../../../otp-input";
import config from "../../../../config";

const MFAEmailPanel = () => {
  const {loading, DataHelper, handleApprove, userData, setOtp, setOtpError, otp, inputFocus, setInputFocus, otpError, requestCode, requestCodeVerified, retryEnabled, handleRetry, retryTimer, setMfaMethod, stopPolling, setRetryTimer, setShowMfa} = useContext(mfaContext);
  const [otpLoading, setOtpLoading] = useState(false);

  const handleOtpSubmit = async (otp) => {
    setOtpLoading(true);
    const apiPath = config.backend;
    const response = await fetch(`${apiPath}rest.api/verify/${requestCode}:${otp}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    const data = await response.json();
    console.log(data)
    if (data.success) {
      handleApprove();
    } else {
      setOtpError("Invalid code");
    }

    setOtpLoading(false);
  };

  return (
    <div className="mfa-panel">
      <p className="mfa-heading pb-3">{"VERIFY YOUR LOGIN"}</p>
      <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
        <>
         <span>
           {`An email has been sent to your registered email address that ends with `}
         </span>
         <strong>
           {DataHelper.characterHide(userData?.email)}
         </strong>
       </>
      }</p>
      <p className="mfa-sub-heading text-justify">
         <strong>{`please click on on the approval link provided `}</strong>
         <span>{`or enter the code below to continue.`}</span>
      </p>

      {loading ? <i className="fad fa-spinner"/> : <OtpInput
        count={6}
        value={otp}
        className="otp-input-field"
        onChange={value => {
          setOtp(value);
          setOtpError(null);
        }}
        inputFocus={inputFocus}
        setInputFocus={setInputFocus}
      />}


      {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
      
      <div className="mfa-actions-container">
        {!requestCodeVerified && !retryEnabled && otp.join("").length === 6 &&<Button
          className="submit-otp-button"
          onClick={() => handleOtpSubmit(otp.join(""))}
          disabled={otpLoading}
        >
          {"Submit"}
        </Button>}

        {!requestCodeVerified && retryEnabled && <div className="retry-container">
          <div className="retry-info">{"Request code expired"}</div>
          <Button
            className="retry-otp-button"
            onClick={handleRetry}
          >
            {"Retry"}
          </Button>
        </div>}

        {!requestCodeVerified && !retryEnabled && retryTimer > 0 && <div className="retry-timer">
          {`retry in ${retryTimer > 60 ? moment.utc(retryTimer * 1000).format("mm:ss") : `${retryTimer} ${retryTimer > 1 ? "seconds" : "second"}`}`}
        </div>}
      </div>

      <hr className="divider"/>
      <div className="option-list-actions">
        <span className="option-list-item" onClick={() => setMfaMethod(null)}>
          {"Use another method to receive the code"} 
        </span>
        <span className="option-list-item" onClick={() => setShowMfa(false)}>
          {"Sign in to a different account"} 
        </span>
      </div>
    </div>
  );
};


export default MFAEmailPanel;