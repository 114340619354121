import React, { useEffect, useState } from "react";
import {Container, Row, Col, Modal} from "react-bootstrap";
import LogoMonoImage from "../../images/vostro-logo-pack/vostronet-logo-cmyk.svg";
import RegisterForm from "../section/register";
import config from "../../config";
import LoginControl from "../../controls/login";
import ForgotPassword from "../forgot-password/index";
import MfaPanel from "./mfa";


const LoginPage = (props) => {
  const [register, setRegister] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showMfa, setShowMfa] = useState(false);
  const [mfaMethod, setMfaMethod] = useState(null);
  const [allowedOptions, setAllowedOptions] = useState(["admin-generated-otp", "user-totp"]);
  const [mfa, setMfa] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (userData) {
      setShowMfa(false);
    }
  }, []);

  const handleClickForgotPassword = async (e) => {
    e.preventDefault();
    return setShowForgotPassword(true);
  }

  const handleClickSignup = async (e) => {
    return window.location = "/join-us/";
  }

  const onCloseForgotPassword = async () => {
    return setShowForgotPassword(false);
  }


  return (
    <Container fluid className={`fh no-gutters vw-login-page `}>
      <div className="vw-login-page-overlay">
      <Row>
        <Col className="no-gutters">
          {showForgotPassword && <ForgotPassword onClose={onCloseForgotPassword} />}
          {register &&
            <Container className="mb-5">
              <Container>
                <RegisterForm setFullHeight={async (data) => setFullHeight(data)} backToLogin={async () => setRegister(false)} />
              </Container>
            </Container>
          }
          {!register && <Container fluid className="vw-login-container no-gutters">
            <Row className="pb-4">
              <Col>
                <Container className="logo">
                  <a className="link" href="https://vostronet.com/" target="_blank"><img src={LogoMonoImage} /></a>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                  <Modal
                    show={showMfa}
                    className="login-mfa-panel-modal"
                    onClose={() => {
                      showMfa(false);
                    }}
                  >
                    <MfaPanel
                    mfaMethod={mfaMethod}
                    setShowMfa={setShowMfa}
                    mfa={mfa}
                    allowedOptions={allowedOptions}
                    setMfaMethod={setMfaMethod}
                    userData={userData}
                    setUserData={setUserData} 
                    />
                  </Modal>
                  <div className="login-container">
                    <div className="title">
                      {"Log in to your account"}
                    </div>
                    <LoginControl
                      authUrl={config.auth}
                      portalUrl={config.portal}
                      apiUrl={config.backend}
                      setMfa={setMfa}
                      setShowMfa={setShowMfa}
                      setAllowedOptions={setAllowedOptions}
                      setUserData={setUserData}
                    >
                      <Row className="no-gutters">
                        <Col xs={12}><hr/></Col>
                      </Row>
                      <Row className="vw-login-control">
                        <Col xs="auto">
                          <a className="signup" onClick={handleClickSignup}>{"Sign up for an account"}</a>
                        </Col>
                        <Col xs="auto" >
                          <a className="forgot-password" onClick={handleClickForgotPassword}>{"Unable to log in?"}</a>
                        </Col>
                      </Row>
                    </LoginControl>
                  </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="auto" className="mx-auto">
                <div className="links-container">
                  <a className="help-us" target="_blank" href="https://help.vostro.cloud/help">{"Help Center"}</a>
                  <span>|</span>
                  <a className="e-safety" target="_blank" href="/esafety">eSafety</a>
                </div>
              </Col>
            </Row>
          </Container>}
        </Col>
      </Row>
      </div>
    </Container>
  );
}


export default LoginPage;