import React, {useRef, Fragment} from "react";
import {Modal, Container, Row, Col, Button} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";
import Formsy from "formsy-react";

import TextField from "../bootstrap/input";
import Captcha from "../bootstrap/captcha";
import Verification from "./verification";

export default function Index(props) {
  const {onClose} = props;
  const [loading, setLoading] = useAsyncSetState(false);
  const [errorMessage, setErrorMessage] = useAsyncSetState(null);
  const [captchaRefreshKey, setRefreshKey] = useAsyncSetState(new Date());
  const [showVerification, setVerification] = useAsyncSetState(false);

  const formRef = useRef(null);

  const handleSubmit = async ({email, captcha, captchaKey}) => {
    try {
      await setLoading(true);
      const response = await fetch(
        `${process.env.BACKEND_URL}rest.api/forgot-request`,
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            captcha,
            captchaKey,
          }),
        }
      );
      switch (response.status) {
        case 503:
          await setStateAsync(() => <>{"An error has occurred"}</>);
          await setRefreshKey(new Date());
          break;
        case 401:
          await setRefreshKey(new Date());
          const {error} = await response.json();
          switch (error) {
            case "EEXISTS":
              setErrorMessage(
                <Fragment>
                  {"This email address already registered, "}
                  <Link to="/forgot-password">{"Click Here"}</Link>
                  {" to reset your password"}
                </Fragment>
              );
              break;
            case "ECAPTCHA":
              formRef.current.updateInputsWithError({
                captcha: "Invalid captcha code provided",
              });
              break;
            default:
              await setErrorMessage(error);
              break;
          }
          break;
        case 200:
          await setErrorMessage(null);
          await setVerification(true);
        // return window.location = `/welcome?firstName=${data.firstName}&userName=${userName}`;
      }
    } catch (err) {
      console.log("err", err);
      await setErrorMessage("An error has occurred");
    }
    return setLoading(false);
  };

  const handleRegenerateCaptcha = () => {
    if (formRef) {
      formRef.current.updateInputsWithValue({
        captcha: "", // Resets captcha value
      });
    }
  };

  return (
    <Modal
      aria-hidden={true}
      size={"md"}
      backdrop="static"
      keyboard={true}
      show={true}
      onHide={onClose}
      centered
      className="fp-modal"
    >
      {showVerification ? (
        <Verification setStateVerification={setVerification} />
      ) : (
        <Formsy ref={formRef} onValidSubmit={handleSubmit}>
          <Fragment>
            <Modal.Body className="fp-modal-body">
              {errorMessage && (
                <Row>
                  <Col>
                    <div className="alert alert-danger">{errorMessage}</div>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <h3 className="text-center mb-3 mt-2">Forgot Password?</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="text-center mb-0">
                    Enter the email you use to register,
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="text-center mb-0">
                    and we'll help you create a new password
                  </p>
                </Col>
              </Row>
              <Row className="field-row mb-2">
                <Col xs={12} className="vw-login-field">
                  <TextField
                    className="vw-form-field"
                    name="email"
                    autoComplete="off"
                    placeholder="Email Address"
                    disabled={loading}
                    required
                    validations={{
                      isEmail: true
                    }}
                    validationErrors={{
                      isEmail: "Please input valid email"
                    }}
                  />
                </Col>
              </Row>
              <Row className="field-row captcha">
                <Col>
                  <Captcha
                    required
                    name="captcha"
                    refreshKey={captchaRefreshKey}
                    onRegenerateCaptcha={handleRegenerateCaptcha}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="fp-modal-footer">
              <Container>
                <Row>
                  <Col xs="auto" className="ml-auto">
                    <Button
                      className="vw-button btn btn-white"
                      type="button"
                      disabled={loading}
                      onClick={(e) => {
                        e.preventDefault();
                        return onClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xs="auto" className="px-1">
                    <Button
                      className="vw-button btn btn-blue"
                      type="submit"
                      disabled={loading}
                    >
                      Request
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
          </Fragment>
        </Formsy>
      )}
    </Modal>
  );
}
