import React, {Fragment} from "react";
import Formsy from "formsy-react";
import {Modal, Container, Row, Col, Button} from "react-bootstrap";
import TextField from "../bootstrap/input";
import {useAsyncSetState} from "use-async-setstate";
import {useRef} from "react";
import {fetch} from "window-or-global";

export default function Verification(props) {
  const {setStateVerification} = props;
  const [loading, setLoading] = useAsyncSetState(false);
  const formRef = useRef(null);

  const handleVerify = async (data) => {
    await setLoading(true);

    try {
      const {code} = data;
      if (!code) {
        await setLoading(false);
        return;
      }

      const apiUrl = process.env.BACKEND_URL;

      let result = await fetch(
        `${apiUrl}rest.api/forgot-verification/${code}`,
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      result = await result.json();

      if (result && result?.success && result?.redirectUrl) {
        window.location.href = result?.redirectUrl;
      }

      if (result && !result.success) {
        formRef.current.updateInputsWithError({
          code: "Invalid verification code provided"
        })
      }
    } catch (err) {
      console.log({err});
      await setLoading(false);
    }

    await setLoading(false);
  };

  return (
    <Fragment>
      <Formsy
        ref={formRef}
        onValidSubmit={(data) => handleVerify(data)}
        onInvalidSubmit={(e) => {
          console.log("error", e);
        }}
      >
        <Modal.Body className="fp-modal-body">
          <Container fluid className="no-gutters">
            <Row>
              <Col>
                <div className="alert alert-light p-0" role="alert">
                  <span className="verification-title">
                    Enter verification code to continue
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p className="mb-0 verification-text mb-3">
                  A sms or email has been sent to your device, enter the one
                  time code to verify your account to reset your password.
                </p>
                <p className=" mb-0 verification-text">
                  If you do not receive a verification code, please go back and
                  check your details and try again.
                </p>
              </Col>
            </Row>
            <Row className="field-row">
              <Col>
                <TextField
                  disabled={loading}
                  autoFocus
                  inputMode="numeric"
                  name="code"
                  placeholder="Verification Code"
                  defaultValue=""
                  required
                  autoComplete="one-time-code"
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col xs="auto mr-auto">
                <Button
                  className="vw-button btn btn-white"
                  onClick={() => setStateVerification(false)}
                >
                  <i className="fas fa-arrow-left mr-2" />
                  Return
                </Button>
              </Col>
              <Col xs="auto ml-auto">
                <Button
                  type="submit"
                  className="vw-button btn btn-blue"
                  disabled={loading}
                >
                  Reset Password
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Formsy>
    </Fragment>
  );
}
