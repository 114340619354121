import React, { Fragment, useRef } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Formsy from "formsy-react";
import TextField from "../components/bootstrap/input";
import { fetch, localStorage, window } from "window-or-global";

import { useAsyncSetState } from "use-async-setstate";

export default function LoginControl(props) {
  let dst;
  const {authUrl, portalUrl, apiUrl, setShowMfa, setAllowedOptions, setUserData, setMfa} = props;

  const formRef = useRef(null);

  const [processing, setProcessing] = useAsyncSetState(false);
  const [loginError, setLoginError] = useAsyncSetState("");
  const [isErr, setIsErr]  = useAsyncSetState(false);
  const [authDetails, setAuthDetails] = useAsyncSetState(false);

  const handleValidLoginSubmit = async (data) => {
    await setProcessing(true);
    try {
      const response = await fetch(`${apiUrl}rest.api/login`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ ...data }),
      });

      if (response.status === 503) {
        await setProcessing(false);
        await setIsErr(true);
        await setLoginError("Something went wrong");
        return;
      }

      const parsedResponse = await response.json();

      if (parsedResponse?.success === "Invalid username/password") {
        await setProcessing(false);
        await setIsErr(true);
        await setLoginError("Invalid username and/or password.");
      }

      if (response.status === 401) {
        await setProcessing(false);
        await setIsErr(true);
        await setLoginError(parsedResponse.error);
      }

      if (parsedResponse.success && parsedResponse.user && !parsedResponse.jwtToken) {
        await setMfa({user: parsedResponse.user, hasUserTOTP: parsedResponse.hasUserTOTP, loginDetails: data})
        if (parsedResponse.user.mobileNumber) {
          setAllowedOptions(prev => [...prev, "sms"]);
        }

        if (parsedResponse.user.email) {
          setAllowedOptions(prev => [...prev, "email"]);
        }

        if (parsedResponse.hasUserTOTP) {
          setAllowedOptions(prev => [...prev, "user-totp"]);
        }

        setUserData(parsedResponse.user);

        await setShowMfa(true);
        return;
      } else {
        if (parsedResponse.jwtToken) {
          localStorage.setItem("authToken", parsedResponse.jwtToken);
        }

        if (parsedResponse.user.id) {
          setAuthDetails(data);
          if (authUrl && authUrl !== "") {
            formRef.current.submit();
          } else {
            window.location.href = `${portalUrl}/`;
          }
        }
      }
    } catch (err) {
      console.log("login error", err);
      await setProcessing(false);
      await setIsErr(true);
      await setLoginError("Invalid username and/or password.");
    }

    await setProcessing(false);
  }


  return (
    <Fragment>
      <form ref={formRef} name="login" action={authUrl} method="post">
        <input type="hidden" value="hotspot" name="radius11" />
        <input
          type="hidden"
          name="dst"
          value={`${portalUrl}`}
        />
        <input type="hidden" name="username" value={authDetails?.userName} />
        <input type="hidden" name="password" value={authDetails?.password} />
      </form>
      <Formsy
        className="vw-login-panel"
        onValidSubmit={handleValidLoginSubmit}
        disabled={processing && !setShowMfa ? true : false}
      >
        <Container fluid>
          {isErr && (<Row>
            <Col>
              <div className="alert alert-danger mb-0">
                {loginError}
              </div>
            </Col>
          </Row>)}
          <Row>
            <Col xs={12} className="vw-login-field" onClick={() => setIsErr(false)}>
              <TextField
                className="vw-form-field"
                name="userName"
                autoComplete="off"
                placeholder="Username"
                disabled={processing && !setShowMfa ? true : false}
                required
                inline
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="vw-login-field" onClick={() => setIsErr(false)}>
              <TextField
                className="vw-form-field"
                name="password"
                autoComplete="off"
                placeholder="Password"
                type="password"
                disabled={processing && !setShowMfa ? true : false} 
                required
                inline
              />
            </Col>
          </Row>
          <Row className="vw-login-buttons">
            <Col xs={12}  className="vw-loginbtn">
              <Button
                className="vw-button btn-login w-100"
                type="submit"
                disabled={processing && !setShowMfa ? true : false}
              >
                {"Login"}
              </Button>
            </Col>
          </Row>
          {props.children}
        </Container>
      </Formsy>
    </Fragment>
  );
}
