import { useQuery } from '@apollo/client';
import React from 'react'
import { getCurrentUserQuery } from '../logic/user';
import Loader from '../bootstrap/loader';
import LoginPage from './login-page';

const UserCheck = (props) => {
  const currentUserQuery = useQuery(getCurrentUserQuery);
  if (currentUserQuery.loading) {
    return <Loader />;
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;

  if (user) {
    window.location = "/portal/";
    return <Loader />;
  }

  return <LoginPage {...props} />;
}


export default UserCheck